import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  OneStepCheckout,
  OrderError,
  OrderSuccess,
} from '@mfe/features/add-ons';
import { selectConfig } from '@mfe/shared/redux/config';
import {
  purchaseAddOn,
  selectAddOns,
  selectCurrentShopAddon,
} from '@mfe/to-be-migrated/redux/addOns';
import { ConfirmPurchaseLoading } from '@mfe/shared/components/src';

const CheckoutPage: React.FC = () => {
  const history = useHistory();
  const { showAddonsShop } = useSelector(selectConfig);

  const { submitOrder } = useSelector(selectAddOns);

  const currentShopAddon = useSelector(selectCurrentShopAddon);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!showAddonsShop) {
      history.replace('/');
    }
  }, [history, showAddonsShop]);

  const submitOrderHandler = () => {
    dispatch(
      purchaseAddOn({
        productTypeId: currentShopAddon?.id || '',
        shoppingCartId: currentShopAddon?.offer_id || '',
      })
    );
  };

  if (!showAddonsShop) {
    return <></>;
  }

  if (submitOrder.loading) {
    return (
      <ConfirmPurchaseLoading
        title={'confirmPurchase.title'}
        description={'confirmPurchase.description'}
      />
    );
  }

  if (submitOrder.error) {
    return <OrderError />;
  }
  if (submitOrder.success) {
    return <OrderSuccess />;
  }

  return <OneStepCheckout submitOrderHandler={submitOrderHandler} />;
};

export default CheckoutPage;
