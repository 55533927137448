import { useTranslation } from 'react-i18next';
import { EmptyState, Surface } from '@vst/beam';
import useNavigate from '@mfe/services/navigation';

import { CheckMark } from '@vst/beam-icons/illustrative-icons';
import { Storage, useScreenResolution } from '@mfe/shared/util';

import styles from './styles.module.scss';
import { StackLink } from '@mfe/legacy/mv/utils/Navigation';

import { useDispatch } from 'react-redux';
import { fetchUserAction } from '@mfe/to-be-migrated/redux/auth';
import { Container } from '@mfe/shared/components';

export const OrderSuccess = () => {
  const { t } = useTranslation('ShopAddons');
  const { goTo, replace } = useNavigate();
  const { isSmall, isExtraSmall } = useScreenResolution();

  const dispatch = useDispatch();

  const isMobile = isSmall || isExtraSmall;

  return (
    <Container>
      <Surface
        className={styles['container']}
        data-cy="order-confirmation-success"
        radius="16px"
      >
        <EmptyState
          py={'40px'}
          px={isMobile ? '24px' : '40px'}
          icon={CheckMark}
          title={t('success.title')}
          description={t('success.description')}
          actions={{
            primary: {
              label: t('success.primaryLabel'),
              onClick: () => {
                dispatch(fetchUserAction());
                goTo('ShopAddons');
              },

              variant: 'primary',
            },
            secondary: {
              label: t('success.secondaryLabel'),
              onClick: () => {
                dispatch(fetchUserAction());
                Storage.setItem('showInternetPlan', true);
                replace(`/${StackLink.Profile}`);
              },
              variant: 'secondary',
            },
          }}
          actionsLayout={'vertical'}
        />
      </Surface>
    </Container>
  );
};
