import { FetchWithErrorsQuery } from '@mfe/shared/redux/graphql';
import { Addon, ProductType } from '@mfe/shared/schema-types';
import { createSlice } from '@reduxjs/toolkit';

export enum AddOnType {
  Voice = 'Voice',
  EasyCare = 'EasyCare',
  Shield = 'Shield',
  StaticIP = 'StaticIP',
}

export interface AddOnsState {
  loading: boolean;
  error: FetchWithErrorsQuery['errors'] | null;
  addOns: {
    hasEasyCare?: boolean;
    hasVoice?: boolean;
    hasShield?: boolean;
    hasStaticIP?: boolean;
    hasOfficeHours?: boolean;
  };
  addOnsProductInstanceIds: string[];
  addOnsPricesAndDiscounts: [];
  availableAddons: {
    loading: boolean;
    error: FetchWithErrorsQuery['errors'] | null;
    productTypes: Array<ProductType> | null;
  };

  currentShopAddonId: string | null;
  currentAddons: Array<Addon> | null;

  submitOrder: {
    loading: boolean;
    error: FetchWithErrorsQuery['errors'] | null;
    success: boolean;
  };
}

export const initialAddOnsState: AddOnsState = {
  loading: true,
  error: null,
  addOns: {},
  addOnsProductInstanceIds: [],
  addOnsPricesAndDiscounts: [],
  availableAddons: {
    loading: false,
    error: null,
    productTypes: null,
  },
  currentShopAddonId: null,
  currentAddons: null,

  submitOrder: {
    loading: false,
    error: null,
    success: false,
  },
};

export const addOnsSlice = createSlice({
  name: 'addOns',
  initialState: initialAddOnsState,
  reducers: {
    fetchAddOns: (state) => {
      state.loading = true;
    },
    setAddOns: (
      state,
      { payload }: { payload: Partial<AddOnsState['addOns']> }
    ) => {
      state.loading = false;
      state.addOns = { ...state.addOns, ...payload };
    },

    clearAddOnsProductInstanceIds: (state) => {
      state.addOnsProductInstanceIds = [];
    },

    setAddOnsProductInstanceIds: (
      state,
      { payload }: { payload: string[] | [] }
    ) => {
      state.addOnsProductInstanceIds.push(...payload);
    },

    getAddOnsPricesAndDiscounts: (state) => {
      state.loading = true;
    },
    setAddOnsPricesAndDiscounts: (state, { payload }) => {
      state.loading = false;
      state.addOnsPricesAndDiscounts = payload;
    },

    setCurrentShopAddonId: (state, { payload }: { payload: string | null }) => {
      state.currentShopAddonId = payload;
    },

    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    getPurchasableAddOns: (state) => {
      state.availableAddons.loading = true;
    },
    setPurchasableAddOns: (
      state,
      { payload }: { payload: Array<ProductType> }
    ) => {
      state.availableAddons.loading = false;
      state.availableAddons.productTypes = payload;
    },
    purchasableAddOnsError: (state, { payload }) => {
      state.availableAddons.loading = false;
      state.availableAddons.error = payload;
    },

    purchaseAddOn: (
      state,
      {
        payload,
      }: {
        payload: {
          productTypeId: string;
          shoppingCartId: string;
        };
      }
    ) => {
      state.submitOrder.loading = true;
    },

    purchaseAddOnSuccess: (state, { payload }) => {
      state.submitOrder.loading = false;
      state.submitOrder.error = null;
      state.submitOrder.success = payload;
    },

    purchaseAddOnError: (state, { payload }) => {
      state.submitOrder.loading = false;
      state.submitOrder.error = payload;
    },

    resetAddOns: () => initialAddOnsState,

    resetSubmit: (state) => {
      state.submitOrder.loading = false;
      state.submitOrder.error = null;
    },

    setCurrentAddons: (
      state,
      { payload }: { payload: Array<Addon> | null }
    ) => {
      state.currentAddons = payload;
    },
  },
});

export const {
  fetchAddOns,
  setAddOns,
  resetAddOns,
  setAddOnsProductInstanceIds,
  getAddOnsPricesAndDiscounts,
  setError,
  setAddOnsPricesAndDiscounts,
  setCurrentShopAddonId,
  setCurrentAddons,
  purchaseAddOn,
  purchaseAddOnSuccess,
  purchaseAddOnError,
  getPurchasableAddOns,
  setPurchasableAddOns,
  purchasableAddOnsError,
  resetSubmit,
  clearAddOnsProductInstanceIds,
} = addOnsSlice.actions;

export const selectAddOns = (state: { addOns: AddOnsState }) => state.addOns;
export const selectCurrentShopAddon = (state: { addOns: AddOnsState }) => {
  const { currentShopAddonId } = state.addOns;

  const currentShopAddon = state.addOns.availableAddons.productTypes?.find(
    (addon) => addon.id === currentShopAddonId
  );

  if (!currentShopAddon) {
    return null;
  }

  return currentShopAddon;
};
