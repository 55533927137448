import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Surface,
  Button,
  Txt,
  InlineLink,
  Icon,
  StandardModal,
  Spinner,
} from '@vst/beam';
import { Offer } from '@vst/beam-icons/icons';

import {
  AEMContentProvider,
  AddonIcon,
  AemFeature,
  HeaderWithBackNavigation,
  createAemUrl,
} from '@mfe/shared/components';
import { ADDON_PRODUCT_KIND } from '@mfe/shared/util';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import {
  selectCurrentShopAddon,
  setCurrentShopAddonId,
} from '@mfe/to-be-migrated/redux/addOns';
import useNavigate from '@mfe/services/navigation';
import { ProductType } from '@mfe/shared/schema-types';
import { getPriceAndDiscount } from '../addons-grid/utils';
import { ShopAddonsLink } from '@mfe/legacy/mv/utils/Navigation';
import { Confirm } from '@mfe/shared/components';

import styles from './styles.module.scss';

const OneStepCheckout = ({
  submitOrderHandler,
}: {
  submitOrderHandler: () => void;
}) => {
  const { t } = useTranslation('ShopAddons');
  const dispatch = useDispatch();
  const { goBack, replace } = useNavigate();

  const { aemBaseUrl } = useSelector(selectConfig);
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);
  const currentShopAddon = useSelector(selectCurrentShopAddon);

  const [aemFragmentLoading, setAEMFragmentLoading] = useState(true);
  const [aemFragmentError, setAEMFragmentError] = useState(false);

  useEffect(() => {
    if (!currentShopAddon) {
      replace(`/${ShopAddonsLink.ShopAddons}`);
    }
  }, [replace, currentShopAddon]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentShopAddonId(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentShopAddon) {
    return null;
  }

  const aemURL = createAemUrl({
    baseUrl: aemBaseUrl,
    locale: userLocale,
    feature: mapAddonKindToAemUrlFragment(currentShopAddon.kind),
    accountType,
  });

  return (
    <div className={styles['wrapper']}>
      {aemFragmentError ? (
        <Error goBack={() => replace(`/${ShopAddonsLink.ShopAddons}`)} />
      ) : (
        <>
          <HeaderWithBackNavigation
            handleBackNavigation={goBack}
            title={currentShopAddon.name}
            goBackLabel={t('allAddOns')}
            additionalClassName={styles['header']}
          />
          <div className={styles['container']}>
            <Surface className={styles['aem-fragment-surface']}>
              {aemFragmentLoading && (
                <div style={{ padding: '32px' }}>
                  <Spinner label={t('cart.aemFragment.loading')} />
                </div>
              )}
              <AEMContentProvider
                id={styles['aem-fragment-container']}
                style={{ display: aemFragmentLoading ? 'none' : 'block' }}
                src={aemURL}
                onLoad={() => setAEMFragmentLoading(false)}
                onError={() => setAEMFragmentError(true)}
              />

              <div className={styles['submit-container']}>
                <Button onClick={submitOrderHandler}>
                  {t('subscribeNow')}
                </Button>
              </div>
            </Surface>

            <AddonCart
              addon={currentShopAddon}
              submitOrderHandler={submitOrderHandler}
            />
          </div>
        </>
      )}
    </div>
  );
};

type AddonCartProps = {
  addon: ProductType;
  submitOrderHandler: () => void;
};

const AddonCart: React.FC<AddonCartProps> = ({ addon, submitOrderHandler }) => {
  const { t } = useTranslation('ShopAddons');
  const { name, kind } = addon;
  const {
    price = undefined,
    oldPrice = undefined,
    totalPromotionDuration = undefined,
    promotions = [],
  } = getPriceAndDiscount(addon);

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const hasPromotions = promotions.length > 0;

  function handleTermsModalClose() {
    setIsTermsModalOpen(false);
  }

  return (
    <div className={styles['cart']}>
      <Surface radius="16px" p="24px">
        <div className={styles['vertical-spacing']}>
          <Txt variant="bodyLargeBold" color="subtle">
            {t('cart.title')}
          </Txt>
          <div className={styles['content']}>
            <Addon name={name} kind={kind} />
            <div className={styles['subscription-price']}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Txt variant="bodySmallRegular" color="regular">
                  {t('cart.subscription')}
                </Txt>
                <Txt variant="bodySmallRegular" color="regular">
                  {t('cart.pricePerMonth', { price: oldPrice ?? price })}
                </Txt>
              </div>
              {hasPromotions && (
                <div className={styles['promotions']}>
                  {promotions.map((promotion, i) => (
                    <div key={i} className={styles['promotion']}>
                      <Txt
                        component="p"
                        variant="bodySmallBold"
                        color="success"
                        className={styles['promotion-name']}
                      >
                        <span>{promotion.name}</span>
                        <Icon icon={Offer} size={16} />
                      </Txt>
                      <Txt
                        variant="bodySmallBold"
                        color="success"
                        className={styles['promotion-price']}
                      >
                        {t('cart.pricePerMonth', {
                          price: Math.abs(promotion.amount),
                          context: 'discount',
                        })}
                      </Txt>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={styles['total']}>
              <Txt variant="heading6" color="regular">
                {t('cart.total')}
              </Txt>
              <Txt
                variant="heading6"
                color="regular"
                style={{ textAlign: 'right' }}
              >
                {price === 0
                  ? t('cart.free')
                  : t('cart.pricePerMonth', {
                      price,
                    })}
                <div>
                  {hasPromotions &&
                    oldPrice !== undefined &&
                    totalPromotionDuration !== undefined && (
                      <Txt component="p" variant="smallItalic" color="subtle">
                        {t('cart.priceAfterDiscount', {
                          price: oldPrice,
                          duration: totalPromotionDuration,
                        })}
                      </Txt>
                    )}
                  <Txt component="p" variant="smallRegular" color="regular">
                    {t('cart.taxes')}
                  </Txt>
                </div>
              </Txt>
            </div>
          </div>
          <Txt component="p" variant="smallRegular" color="subtle">
            {t('cart.termsAndConditions.beforeLink')}{' '}
            <InlineLink
              variant="primary"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsTermsModalOpen(true);
              }}
            >
              {t('cart.termsAndConditions.link')}
            </InlineLink>
            {t('cart.termsAndConditions.afterLink')}
          </Txt>
          <Button width="100%" onClick={submitOrderHandler}>
            {t('subscribeNow')}
          </Button>
          <Txt component="p" variant="tinyRegular" color="subtle">
            {t('cart.taxesDisclaimer')}
          </Txt>
        </div>
      </Surface>

      <StandardModal
        isOpen={isTermsModalOpen}
        header="Terms and Conditions"
        actions={{
          primary: { label: 'Close', onClick: handleTermsModalClose },
        }}
        onClose={handleTermsModalClose}
        size="medium"
      >
        <div>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis
          deleniti laboriosam ipsum culpa nihil quibusdam temporibus quis hic,
          distinctio, similique blanditiis vero atque possimus veniam neque
          tempora animi et molestias. Sit sequi ipsam suscipit deserunt
          dignissimos exercitationem consequuntur doloribus mollitia. Nostrum
          cumque laboriosam totam alias ex ut, officia deleniti quibusdam, quos
          rerum minus! Voluptate reprehenderit asperiores, consequuntur tenetur
          vero facere! Dolorum itaque minima a dignissimos, provident fuga
          tempore molestiae corrupti impedit earum explicabo neque unde nam illo
          dolor maxime possimus? Odio nemo deserunt dolorum ipsum dicta,
          perferendis fuga sunt facilis! Enim odit nulla in laboriosam
          recusandae dignissimos sint numquam quasi reprehenderit quos,
          voluptatibus ex non accusamus aspernatur asperiores!
        </div>
      </StandardModal>
    </div>
  );
};

type AddonProps = {
  name: string;
  kind: string;
};

const Addon: React.FC<AddonProps> = ({ name, kind }) => {
  return (
    <div className={styles['addon']}>
      <AddonIcon kind={kind} className={styles['icon']} />
      <Txt variant="paragraphBold" color="regular">
        {name}
      </Txt>
    </div>
  );
};

const Error = ({ goBack }: { goBack: () => void }) => {
  const { t } = useTranslation('ShopAddons');

  return (
    <Confirm
      title={t('cart.aemFragment.error.title')}
      description={t('cart.aemFragment.error.description')}
      buttons={[
        {
          children: t('cart.aemFragment.error.goBackLabel'),
          onClick: () => {
            goBack();
          },
        },
      ]}
    />
  );
};

function mapAddonKindToAemUrlFragment(kind: string) {
  if (kind === ADDON_PRODUCT_KIND.voice) {
    return AemFeature.ADD_ON_VOICE;
  }

  if (kind === ADDON_PRODUCT_KIND.shield) {
    return AemFeature.ADD_ON_SHIELD;
  }

  if (kind === ADDON_PRODUCT_KIND.easyCare) {
    return AemFeature.ADD_ON_EASYCARE;
  }

  return AemFeature.ADD_ON_STATIC_IP;
}

export default OneStepCheckout;
