import { all, call, select, takeLatest, put, take } from 'redux-saga/effects';
import { gql } from '@apollo/client';

import {
  AddOnsState,
  selectAddOns,
  setAddOnsPricesAndDiscounts,
  setAddOnsProductInstanceIds,
  setError,
  fetchAddOns,
  purchaseAddOn,
  purchaseAddOnSuccess,
  getPurchasableAddOns as getPurchasableAddOnsAction,
  setPurchasableAddOns,
  purchasableAddOnsError,
  purchaseAddOnError,
} from './addOnsSlice';

import {
  FetchWithErrorsMutation,
  FetchWithErrorsQuery,
  graphqlMutationWithErrors,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';

export const GET_ADD_ONS_PRICES_AND_DISCOUNTS = gql`
  query getAddOnsPricesAndDiscounts($addOnsProductInstanceIds: [String]) {
    getAddOnsPricesAndDiscounts(
      addOnsProductInstanceIds: $addOnsProductInstanceIds
    ) {
      addOnName
      addOnPrice
      discountName
      discountEndDate
      discountAmount
      priceWithDiscount
    }
  }
`;

export const GET_PURCHASABLE_ADDONS = gql`
  query getPurchasableAddOns {
    getPurchasableAddOns {
      id
      kind
      name
      description
      characteristics {
        name
        value
      }
      discounts {
        total_discounts {
          name
          duration
          amount {
            value
            currency {
              minor_units
              numeric_code
              major_unit_symbol
              alphabetic_code
            }
          }
        }
        itemized_discounts {
          name
          duration
          amount {
            value
            currency {
              minor_units
              numeric_code
              major_unit_symbol
              alphabetic_code
            }
          }
        }
      }
      offer_id
      marketing_copy {
        ui_behaviors {
          characteristics {
            name
            value
          }
        }
        translations {
          language
          characteristics {
            name
            value
          }
        }
      }
      prices {
        amount {
          value
        }
      }
    }
  }
`;

export const PURCHASE_ADD_ON = gql`
  mutation purchaseAddOn($input: PurchaseAddOnInput!) {
    purchaseAddOn(input: $input) {
      success
      message
      orderId
    }
  }
`;

export function* waitForAddOnsProductInstanceIds() {
  const addons: AddOnsState = yield select(selectAddOns);

  if (addons.loading) {
    yield take(setAddOnsProductInstanceIds.type);
  }
}

export function* fetchPurchasableAddOns() {
  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_PURCHASABLE_ADDONS,
    variables: {},
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(purchasableAddOnsError(runtimeError ?? errors));
    return;
  }

  const addons = data?.getPurchasableAddOns;

  if (addons) {
    yield put(setPurchasableAddOns(addons));
  }
}

export function* fetchAddOnsPricesAndDiscounts(payload: unknown) {
  yield put(fetchAddOns);

  const { addOnsProductInstanceIds } = yield select(selectAddOns);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_ADD_ONS_PRICES_AND_DISCOUNTS,
    variables: { addOnsProductInstanceIds },
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setError(runtimeError ?? errors));
    return;
  }

  if (data?.getAddOnsPricesAndDiscounts) {
    yield put(
      setAddOnsPricesAndDiscounts(data.getAddOnsPricesAndDiscounts ?? [])
    );
  }
}

export function* purchaseAddOnSaga(action: any) {
  const input = action.payload;

  const apiResponse: FetchWithErrorsMutation = yield call(
    graphqlMutationWithErrors,
    { mutation: PURCHASE_ADD_ON, variables: { input } }
  );

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(purchaseAddOnError(runtimeError ?? errors));
    return;
  }

  if (data?.purchaseAddOn?.success) {
    yield put(purchaseAddOnSuccess(data.purchaseAddOn.success));
  }

  if (!data?.purchaseAddOn?.success) {
    yield put(purchaseAddOnError('Operation unsuccessful'));
  }
}

export function* watchAddOns() {
  yield all([
    takeLatest(setAddOnsProductInstanceIds.type, fetchAddOnsPricesAndDiscounts),
    takeLatest(purchaseAddOn.type, purchaseAddOnSaga),
    takeLatest(getPurchasableAddOnsAction.type, fetchPurchasableAddOns),
  ]);
}
